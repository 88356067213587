import { PrimaryButton, Text } from 'components';
import ContinueButton from 'pages/start/quiz/components/QuizContinueButton';
import React from 'react';
import type { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';
import checkoutSvgs from 'utils/constants/checkoutSvgs';

interface BoxListProps {
  title: string;
  list: {
    title: string;
    text: string;
    svg: string;
  }[];
  buttonText?: string;
  onClick?: () => void;
}

const Container = styled.div`
  background-color: #fff;
  padding: 4rem 1rem;
  @media ${tablet} {
    padding: 2.25rem 1rem;
  }
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 28.75rem;
  width: 100%;
  margin: 0 auto;
`;

const Title = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 900;
  line-height: 2rem;
  padding-bottom: 1.5rem;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;

const Card = styled.div`
  display: flex;
  padding: 0.75rem 1.5rem;
  align-items: center;
  gap: 1rem;
  border-radius: 0.75rem;
  border: 1px solid rgba(119, 119, 119, 0.15);
  @media ${tablet} {
    padding: 0.75rem;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const CardTitle = styled(Text)`
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 900;
  line-height: 1.375rem;
`;

const CardText = styled(Text)`
  color: rgba(0, 0, 0, 0.7);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem; /* 150% */
`;

const SvgContainer = styled.div`
  height: 3rem;
  width: 3rem;
  svg {
    height: 3rem;
    width: 3rem;
  }
`;

const BoxList: FC<BoxListProps> = ({ title, list, buttonText, onClick }) => {
  const renderSvgImage = (svgKey: string) => {
    const Svg = checkoutSvgs[svgKey];
    return (
      <SvgContainer>
        <Svg />
      </SvgContainer>
    );
  };
  return (
    <Container>
      <Inner>
        <Title>{title}</Title>

        <ListContainer>
          {list.map((card, index) => (
            <Card key={index}>
              {renderSvgImage(card.svg)}
              <TextContainer>
                <CardTitle>{card.title}</CardTitle>
                <CardText>{card.text}</CardText>
              </TextContainer>
            </Card>
          ))}
        </ListContainer>
      </Inner>
      {buttonText && (
        <ContinueButtonStyled title={buttonText} onClick={onClick} />
      )}
    </Container>
  );
};

export default BoxList;

const ContinueButtonStyled = styled(ContinueButton)`
  margin: 0 auto;
  margin-top: 1.5rem;
  @media ${tablet} {
    margin-top: 0;
  }
`;
