import challenges from 'assets/icons/checkout/challenges.svg';
import meal from 'assets/icons/checkout/meal.svg';
import meditation from 'assets/icons/checkout/meditation.svg';
import mindfulness from 'assets/icons/checkout/mindfulness.svg';
import plan from 'assets/icons/checkout/plan.svg';
import trackers from 'assets/icons/checkout/trackers.svg';
import afterDes from 'assets/icons/checkout/after-des.svg';
import afterMob from 'assets/icons/checkout/after-mob.svg';
import midsizedDes from 'assets/icons/checkout/midsized-des.svg';
import midsizedMob from 'assets/icons/checkout/midsized-mob.svg';
import overweightDes from 'assets/icons/checkout/overweight-des.svg';
import overweightMob from 'assets/icons/checkout/overweight-mob.svg';
import plumpDes from 'assets/icons/checkout/plump-des.svg';
import plumpMob from 'assets/icons/checkout/plump-mob.svg';
import slimDes from 'assets/icons/checkout/slim-des.svg';
import slimMob from 'assets/icons/checkout/slim-mob.svg';

export default {
  challenges,
  meal,
  meditation,
  mindfulness,
  plan,
  trackers,
  afterDes,
  afterMob,
  midsizedDes,
  midsizedMob,
  overweightDes,
  overweightMob,
  plumpDes,
  plumpMob,
  slimDes,
  slimMob,
};
